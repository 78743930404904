<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ editMode ? "Edit": "Create" }} Dashboard
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="dashboard_name">Dashboard Name</label>
                  <InputText
                    id="dashboard_name"
                    v-model="createEditDashboardForm.name"
                    placeholder="Name"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="company_id">Company</label>
                  <Dropdown
                    id="company_id"
                    v-model="selectedCompany"
                    :loading="loadingCompanies"
                    :editable="true"
                    :options="companies"
                    option-label="name"
                    placeholder="Select a Company"
                    class="grey-input flex-item"
                    @keyup="debounceSearchCompanies(selectedCompany)"
                    @change="selectCompany(selectedCompany)"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="dashboard_type">Dashboard Type</label>
                  <Dropdown
                    id="dashboard_type"
                    v-model="createEditDashboardForm.dashboard_type"
                    :options="dashboardTypes"
                    option-label="name"
                    option-value="value"
                    placeholder="Select Dashboard Type"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="dashboard_id">Dashboard ID</label>
                  <InputText
                    id="dashboard_id"
                    v-model="createEditDashboardForm.dashboard_id"
                    placeholder="Dashboard ID"
                    class="grey-input"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="domain_url">Domain URL</label>
                  <InputText
                    id="domain_url"
                    v-model="createEditDashboardForm.domain_url"
                    placeholder="Domain URL"
                    class="grey-input"
                  />
                </div> 
              </div>
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="createEditDashboardError && createEditDashboardError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ createEditDashboardError }}</span>
          </div>
          <div
            v-else-if="createEditDashboardSuccess && createEditDashboardSuccess !==''"
            class="success-message" 
          >
            <span class="error-text-span">{{ createEditDashboardSuccess }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="activate"
          >
            {{ actionButtonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AdminCreateEditAnalyticsDashboardModal',
  components: {
  },
  props: {
    /**
     * The selected trials
     * @type {Object}
     */
    dashboard: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  emits: [
    'closeModal',
    'dashboardCreated',
    'dashboardEdited',
  ],
  data() {
    return {
      selectedDashboard: {},
      createEditDashboardForm: {
        name: '',
        company_id: '',
        dashboard_type: '',
        dashboard_id: '',
        active: true,
        domain_url: 'https://superset.enterprise.strongroom.ai:8088',
        production: true
      },
      dashboardTypes: [
        {name: 'Supplier', value: 'Supplier'},
        {name: 'Advertising', value: 'Advertiser'},
        {name: 'Industry Dashboard', value: 'Industry Dashboard'},
      ],
      loadingCreateDashboard: false,
      createEditDashboardError: '',
      createEditDashboardSuccess: '',      
      editMode: false,
      actionButtonText: 'Create',
      companies: [],
      loadingCompanies: false,
      selectedCompany: null,
    }
  },
  computed: {
    /**
     * Create Trial Payload
     */
    createEditDashboardPayload() {
      let payload = {
        name: this.createEditDashboardForm.name,
        company_id: this.createEditDashboardForm.company_id,
        dashboard_type: this.createEditDashboardForm.dashboard_type,
        dashboard_id: this.createEditDashboardForm.dashboard_id,
        active: this.createEditDashboardForm.active,
        domain_url: this.createEditDashboardForm.domain_url,
        production: this.createEditDashboardForm.production
      };

      return payload;
    },
  },
  async mounted() {
    this.selectedDashboard = this.dashboard;

    if (!this.selectedDashboard || !this.selectedDashboard.dashboard_id) {
      this.editMode = false;
      this.actionButtonText = 'Create';
    }
    else {
      this.editMode = true;
      this.actionButtonText = 'Update';

      this.companies = [this.dashboard.company];
      this.selectedCompany = this.dashboard.company;
      this.selectCompany(this.selectedCompany);

      this.createEditDashboardForm.name = this.dashboard.name;
      this.createEditDashboardForm.dashboard_type = this.dashboard.dashboard_type;
      this.createEditDashboardForm.dashboard_id = this.dashboard.dashboard_id;
      this.createEditDashboardForm.active = this.dashboard.active;
      this.createEditDashboardForm.domain_url = this.dashboard.domain_url;
      this.createEditDashboardForm.production = this.dashboard.production;
    }
  },
  methods: {
    /**
     * Create or Update the Dashboard
    */
    activate() {
      this.$store.dispatch('getRefreshToken');
      this.createEditDashboardError = '';
      let errors = [];
      if (this.createEditDashboardForm.name === '') {
        errors.push('enter a dashboard name');
      }
      if (this.createEditDashboardForm.company_id === '') {
        errors.push('select a company');
      }
      if (this.createEditDashboardForm.dashboard_type === '') {
        errors.push('select a dashboard type');
      }
      if (this.createEditDashboardForm.domain_url === '') {
        errors.push('enter a domain url');
      }
      if (this.createEditDashboardForm.production === '') {
        errors.push('select a production status');
      }
      if (errors.length > 0) {
        // If there are multiple errors, add 'and' before the last error
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        // Join the errors till the second last with a comma and the last with an 'and'
        this.createEditDashboardError = `Please ${errors.join(', ')}.`;
        return;
      }
     
      this.loadingCreateDashboard = true;
      console.log('The dashboard form is: ', this.createEditDashboardForm);
      this.editMode ? this.updateDashboard() : this.createDashboard();
    },     
    /**
     * Create the Dashboard
     */
    async createDashboard() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateDashboard = true;
      this.actionButtonText = 'Creating...';

      // Call the Create Dashboard Endpoint

      try {
        const res = await axios.post('/api/dashboard/admin/create', this.createEditDashboardPayload, this.$store.state.header);
        this.handleDashboardCreateSuccess(res);
      } catch (err) {
        this.handleDashboardCreateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Dashboard Creation Success
     * @param {Object} res
     */
    handleDashboardCreateSuccess(res) {
      console.log('The dashboard creation response is: ', res);
      this.loadingCreateDashboard = false;
      this.actionButtonText = 'Create';
      this.createEditDashboardSuccess = 'Dashboard Created Successfully';
      this.clear();

      this.$emit('dashboardCreated', res); // Emit the created Dashboard when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Dashboard Creation Error
     * @param {Object} err
     */
    handleDashboardCreateError(err) {
      console.log('The Dashboard creation error is: ', err);
      this.loadingCreateDashboard = false;
      this.actionButtonText = 'Create';
      this.createEditDashboardError  = err;
    },
    /**
     * Update the Dashboard
     */
    async updateDashboard() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateDashboard = true;
      this.actionButtonText = 'Updating...';

      // Call the Update Dashboard Endpoint

      try {
        const res = await axios.post(`/api/dashboard/admin/${this.selectedDashboard.id}/edit`, this.createEditDashboardPayload, this.$store.state.header);
        this.handleDashboardUpdateSuccess(res);
      } catch (err) {
        this.handleDashboardUpdateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Dashboard Update Success
     * @param {Object} res
     */
     handleDashboardUpdateSuccess(res) {
      console.log('The dashboard update response is: ', res);
      this.loadingCreateDashboard = false;
      this.createEditDashboardSuccess = 'Dashboard Updated Successfully';
      this.actionButtonText = 'Update';
      this.$emit('dashboardEdited', res); // Emit the updated Dashboard when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Dashboard Update Error
     * @param {Object} err
     */
     handleDashboardUpdateError(err) {
      console.log('The dashboard update error is: ', err);
      this.loadingCreateDashboard = false;
      this.createEditDashboardError  = err;
    },
     /**
       * Debounce search to retrieve companies based on company name
       * 400 ms delay on search
       * @param {String} companyName 
       */
       debounceSearchCompanies(companyName) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchCompanies(companyName);
        }, 400);
      },
      /**
       * Search for companies based on company name
       * @param {String} companyName 
       */
      searchCompanies(companyName) {
        this.$store.dispatch('getRefreshToken');
        this.loadingCompanies = true;
        axios.post('/api/company/search', { "page": 1, "name": companyName }, this.$store.state.header)
        .then(res => {
          this.handleSearchCompaniesSuccess(res);
        }).catch(err => {
          this.handleSearchCompaniesError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the companies
       * @param {Object} res 
       */
      handleSearchCompaniesSuccess(res) {
        console.log('The search companies response is: ', res);
        this.loadingCompanies = false;
        this.companies = res.data.results;
      },
      /**
       * Select a company and set the addUserPayload company_id
       */
      selectCompany(company) {
        if (typeof company === 'object' && company !== null) {
          this.createEditDashboardForm.company_id = company.uuid;
          console.log('The selected company is: ', company);
        }
      },
      /**
       * Handle the error response for the companies
       * @param {Object} err 
       */
      handleSearchCompaniesError(err) {
        console.log('The search companies error is: ', err);
        this.loadingCompanies = false;
      },
    /**
     * Clear the form
     */ 
    clear() {
      this.createEditDashboardForm = {
        name: '',
        company_id: '',
        dashboard_type: '',
        dashboard_id: '',
        active: true,
        domain_url: '',
        production: 1
      };
    },
    /**
     * Close the modal
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

:deep(.p-calendar-w-btn .p-datepicker-trigger) {
  background: $strongblue;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.success-message {
  color: $complete;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

</style>  