<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Edit Company
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="flex-input">
                <label for="company_name">Company Name</label>
                <InputText
                  id="company_name"
                  v-model="editCompanyPayload.name"
                  placeholder="Enter a Company Name"
                  class="grey-input"
                />
              </div>
              <!-- 
                The 60_day_signup is a boolean value
                The checkbox is checked if the value is true
               -->
              <div class="flex-input">
                <label for="sixty_day_signup">60 Day Signup</label>
                <Checkbox
                  v-model="editCompanyPayload.sixty_day_signup"
                  input-id="sixty_day_signup" 
                  :binary="true"
                />
              </div>
              <!-- 
                The cloud_ecdr is a boolean value
                The checkbox is checked if the value is true
               -->
              <div class="flex-input">
                <label for="cloud_ecdr">Cloud eCDR</label>
                <Checkbox
                  v-model="editCompanyPayload.cloud_ecdr"
                  input-id="cloud_ecdr" 
                  :binary="true"
                />
              </div>
            
              <div class="flex-input">
                <label for="ad_dashboard">Advertisement Dashboard</label>
                <Checkbox
                  v-model="editCompanyPayload.ad_dashboard"
                  input-id="ad_dashboard" 
                  :binary="true"
                />
              </div>
              <div class="flex-input flex-item">
                <label for="supplier_dashboard">Supplier Dashboard</label>
                <Checkbox
                  v-model="editCompanyPayload.supplier_dashboard"
                  input-id="supplier_dashboard" 
                  :binary="true"
                />
              </div>
            </div>
            <p
              v-if="editCompanyError"
              class="error-message"
            >
              {{ editCompanyError }}
            </p>
            <p
              v-if="editCompanySuccess"
              class="success-message"
            >
              {{ editCompanySuccess }}
            </p>
          </slot>
        </div>
  
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                The updateFilters method is called when the confirm button is clicked
                The method emits an event to the parent component
               -->
            <div
              class="black-button"
              @click="editCompany"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    company: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      editCompanySuccess: '',
      editCompanyError: '',
      editCompanyPayload: {
        name: this.company.name,
        sixty_day_signup: this.company.sixty_day_signup,
        cloud_ecdr: this.company.cloud_ecdr,
        ad_dashboard: this.company.ad_dashboard,
        supplier_dashboard: this.company.supplier_dashboard
      }
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Edit a company
     * @returns {Promise<void>}
     */
    editCompany() {
      this.$store.dispatch('getRefreshToken');
      this.editCompanyError = '';
      this.editCompanySuccess = '';
      this.loading = true;

      axios.post(`/api/company/${this.company.uuid}/edit`, this.editCompanyPayload, this.$store.state.header)
      .then(res => {
        this.handleEditCompanySuccess(res);
      }).catch(err => {
        this.handleEditCompanyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the company
     * @param {Object} res 
     */
    handleEditCompanySuccess(res) {
      console.log('The edit company response is: ', res);
      this.loading = false;
      this.editCompanySuccess = 'Company Edited Successfully.'
    },
    /**
     * Handle the error response for the company
     * @param {Object} err 
     */
    handleEditCompanyError(err) {
      console.log('The edit company error is: ', err);
      this.loading = false;
      this.editCompanyError = err;
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
