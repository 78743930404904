<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Import Pharmacy
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div>
                <i style="float: left;">
                  Select a company first, then select a pharmacy to import. 
                </i>
                <p 
                  class="error-message"
                  style="float: left;"
                >
                  This will only transfer the pharmacy and its logins. The templates, campaigns, and other data will not be transferred.
                </p>
              </div>
              <div class="form-row">
                <Dropdown
                  id="company_id"
                  v-model="selectedCompany"
                  :loading="loadingCompanies"
                  :editable="true"
                  :options="companies"
                  option-label="name"
                  placeholder="Select a Company"
                  class="grey-input flex-item"
                  @keyup="debounceSearchCompanies(selectedCompany)"
                  @change="selectCompany(selectedCompany)"
                />
                <Dropdown
                  id="location_id"
                  v-model="selectedPharmacy"
                  :loading="loadingLocations"
                  :editable="true"
                  :options="locations"
                  option-label="name"
                  placeholder="Select a Pharmacy"
                  class="grey-input flex-item"
                  @change="selectPharmacy(selectedPharmacy)"
                />
              </div>
            </div>
            <p
              v-if="importPharmacyError"
              class="error-message"
            >
              {{ importPharmacyError }}
            </p>
            <p
              v-if="importPharmacySuccess"
              class="success-message"
            >
              {{ importPharmacySuccess }}
            </p>
          </slot>
        </div>
  
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                The updateFilters method is called when the confirm button is clicked
                The method emits an event to the parent component
               -->
            <div
              class="black-button"
              @click="importPharmacy"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    /**
     * The selected company
     * @type {Object}
     */
    company: {
      type: Object,
      default() {
        return {
        }
      }
    },
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      importPharmacyError: '',
      importPharmacySuccess: '',
      importPharmacyForm: {
        company_id: this.company.uuid,
        import_pharmacy_id: ''
      },
      companies: [],
      loadingCompanies: false,
      selectedCompany: null,
      companyCurrentPage: 1,
      companyPageCount: 1,
      selectedPharmacy: null,
      locations: [],
      loadingLocations: false,
      timeout: null,
    }
  },
  computed: {
    /**
     * The import pharmacy payload
     */
    importPharmacyPayload() {
      return {
        ...this.importPharmacyForm,
      }
    },
  },
  mounted() {
    this.getCompanies();
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
       * Debounce search to retrieve companies based on company name
       * 400 ms delay on search
       * @param {String} companyName 
       */
       debounceSearchCompanies(companyName) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchCompanies(companyName);
        }, 400);
      },
      /**
       * Search for companies based on company name
       * @param {String} companyName 
       */
      searchCompanies(companyName) {
        this.$store.dispatch('getRefreshToken');
        this.loadingCompanies = true;
        axios.post('/api/company/search', { "page": 1, "name": companyName }, this.$store.state.header)
        .then(res => {
          this.handleSearchCompaniesSuccess(res);
        }).catch(err => {
          this.handleSearchCompaniesError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the companies
       * @param {Object} res 
       */
      handleSearchCompaniesSuccess(res) {
        console.log('The search companies response is: ', res);
        this.loadingCompanies = false;
        this.companies = res.data.results;
      },
      /**
       * Handle the error response for the companies
       * @param {Object} err 
       */
      handleSearchCompaniesError(err) {
        console.log('The search companies error is: ', err);
        this.loadingCompanies = false;
      },
      /**
       * Select a company and set the addUserPayload company_id
       */
       selectCompany(company) {
        if (typeof company === 'object' && company !== null) {
          this.searchLocations();
        }
      },
      /**
       * Search for locations based on pharmacy name
       * @param {String} locationName 
       */
       searchLocations() {
        this.$store.dispatch('getRefreshToken');
        this.loadingLocations = true;
        axios.post('/api/company/'+ this.selectedCompany.uuid +'/locations', {}, this.$store.state.header)
        .then(res => {
          this.handleSearchLocationsSuccess(res);
        }).catch(err => {
          this.handleSearchLocationsError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the locations
       * @param {Object} res 
       */
       handleSearchLocationsSuccess(res) {
        console.log('The search locations response is: ', res);
        this.loadingLocations = false;
        this.locations = res.data.data;
      },
      /**
       * Select a company and set the importPharmacyForm import_pharmacy_id
       */
      selectPharmacy(location) {
        if (typeof location === 'object' && location !== null) {
          this.importPharmacyForm.import_pharmacy_id = location.uuid;
        }
      },
      /**
       * Handle the error response for the locations
       * @param {Object} err 
       */
       handleSearchLocationsError(err) {
        console.log('The search locations error is: ', err);
        this.loadingLocations = false;
      },
    /**
     * Import a pharmacy
     * @returns {Promise<void>}
     */
    async importPharmacy() {
      this.$store.dispatch('getRefreshToken');
      this.importPharmacyError = '';
      this.importPharmacySuccess = '';
      this.loading = true;

      await axios.post('/api/import-company-pharmacy', this.importPharmacyPayload, this.$store.state.header)
      .then(res => {
        this.handleImportPharmacySuccess(res);
      }).catch(err => {
        this.handleImportPharmacyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the import pharmacy
     * @param {Object} res 
     */
     handleImportPharmacySuccess(res) {
      console.log('The import pharmacy response is: ', res);
      this.loading = false;
      this.clear();
      this.importPharmacySuccess = 'Pharmacy Imported Successfully.'
    },
    /**
     * Handle the error response for the import pharmacy
     * @param {Object} err 
     */
     handleImportPharmacyError(err) {
      console.log('The import pharmacy error is: ', err);
      this.loading = false;
      this.importPharmacyError = err;
    },
    clear() {
      this.selectedCompanyId = '';
      this.importPharmacyForm.import_pharmacy_id = '';
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
